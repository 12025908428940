/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import styles from '../index.module.scss';
import {
  PodcastIntroInterface,
  PodcastListInterface,
  Podcastfeature,
} from '../store';
import { Button } from '@ui-elements';
import classnames from 'classnames';

interface PodcastBlockProps {
  podcastIntro?: PodcastIntroInterface[];
  podcastBlock?: PodcastListInterface[];
  podcastFeatures?: Podcastfeature[];
}

const PodcastBlock: React.FC<PodcastBlockProps> = ({
  podcastIntro,
  podcastBlock,
  podcastFeatures,
}) => {
  const [contents, setContents] = useState<PodcastListInterface[]>([]);
  const [isLargeThumbnail, setIsLargeThumbnail] = useState(false);

  useEffect(() => {
    const podcastListContents: PodcastListInterface[] = (
      podcastBlock ?? []
    ).map((podcast) => ({
      ...podcast,
      ctaContent: (podcast.ctaContent ?? []).map((cta) => ({ ...cta })),
    }));
    setContents(podcastListContents);
    const firstPodcastFeature = podcastFeatures?.[0] || {};
    setIsLargeThumbnail(
      firstPodcastFeature.thumbnailSize?.toLowerCase() === 'large'
    );
  }, [podcastFeatures, podcastBlock, podcastIntro]);

  // Intro block
  const PodcastIntro: React.FC<{ intro: PodcastIntroInterface }> = ({
    intro: {
      title,
      thumbnail,
      text,
      contentFontColor,
      ctaContent,
      titleFontColor,
      thumbnailPosition,
      ctaAlignment,
      contentGap,
    },
  }) => {
    const [firstCtaContent] = ctaContent || [];
    return (
      <div
        className={classnames(styles.intro, {
          [styles.introRight]: thumbnailPosition === 'Right',
        })}
      >
        <div
          className={classnames(styles.introImage, {
            [styles.introImageRight]: thumbnailPosition === 'Right',
          })}
        >
          <img src={thumbnail} />
        </div>
        <div
          className={styles.introContentContainer}
          style={{
            ...(contentGap && { padding: contentGap }),
          }}
        >
          <div
            className={styles.introContentTitle}
            style={{ color: titleFontColor ? `#${titleFontColor}` : '#323334' }}
            dangerouslySetInnerHTML={{ __html: title || '' }}
          />
          <span
            className={styles.introContentBody}
            style={{
              color: contentFontColor ? `#${contentFontColor}` : '#323334',
            }}
            dangerouslySetInnerHTML={{ __html: text || '' }}
          />
          <div
            className={styles.subscribeBtn}
            style={{
              textAlign: ctaAlignment ? ctaAlignment : 'left',
            }}
          >
            {firstCtaContent && (
              <Button
                href={firstCtaContent.url}
                target="_blank"
                buttonSize="small"
                className={styles.introSubscribeBtn}
              >
                {firstCtaContent.textDisplay}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const PodcastList: React.FC<{ content: PodcastListInterface }> = ({
    content: {
      bgColor,
      showTag,
      tagColor,
      tagFontColor,
      tagName,
      title,
      titleFontColor,
      text,
      contentFontColor,
      ctaContent,
      ctaAlignment,
      thumbnail,
      contentGap,
    },
  }) => {
    const [firstCtaContent] = ctaContent || [];
    return (
      <div
        className={classnames(styles.podcast, {
          [styles.isLarge]: isLargeThumbnail,
        })}
        style={{ backgroundColor: bgColor ? `#${bgColor} ` : '#ffff' }}
      >
        {showTag === 'True' && (
          <div
            className={styles.podcastBanner}
            style={{
              backgroundColor: tagColor ? `#${tagColor} ` : '#EFEFEF',
              color: tagFontColor ? `#${tagFontColor}` : '#646566',
            }}
          >
            {tagName}
          </div>
        )}
        {/* MOBILE VIEW - START */}
        <div
          className={classnames(styles.imageContainer, {
            [styles.isLarge]: isLargeThumbnail,
          })}
        >
          <div
            className={classnames(styles.imageHolder, {
              [styles.isLarge]: isLargeThumbnail,
            })}
          >
            <img src={thumbnail} />
          </div>
        </div>
        <div
          className={classnames(
            styles.contentTitle,
            {
              [styles.isLarge]: isLargeThumbnail,
            },
            styles.mobile
          )}
          style={{
            color: titleFontColor ? `#${titleFontColor}` : '#323334',
          }}
          dangerouslySetInnerHTML={{ __html: title || '' }}
        />
        <div
          className={classnames(
            styles.contentBody,
            {
              [styles.isLarge]: isLargeThumbnail,
            },
            styles.mobile
          )}
          style={{
            color: contentFontColor ? `#${contentFontColor} ` : '#323334',
          }}
          dangerouslySetInnerHTML={{ __html: text || '' }}
        />
        <div
          className={classnames(
            styles.learnMore,
            {
              [styles.isLarge]: isLargeThumbnail,
            },
            styles.mobile
          )}
          style={{
            textAlign: ctaAlignment ? ctaAlignment : 'left',
          }}
        >
          {firstCtaContent ? (
            <Button
              href={firstCtaContent.url}
              target={firstCtaContent.target || '_self'}
              className={styles.learnMoreBtn}
              buttonSize="small"
            >
              {firstCtaContent.textDisplay}
            </Button>
          ) : (
            <></>
          )}
        </div>
        {/* MOBILE VIEW - END */}
        <div
          className={classnames(styles.paddingContainer, {
            [styles.isLarge]: isLargeThumbnail,
          })}
          style={{
            ...(contentGap && { padding: contentGap }),
          }}
        >
          <div
            className={classnames(styles.contentTitle, {
              [styles.isLarge]: isLargeThumbnail,
            })}
            style={{
              color: titleFontColor ? `#${titleFontColor}` : '#323334',
            }}
            dangerouslySetInnerHTML={{ __html: title || '' }}
          />
          <div
            className={classnames(styles.contentBody, {
              [styles.isLarge]: isLargeThumbnail,
            })}
            style={{
              color: contentFontColor ? `#${contentFontColor} ` : '#323334',
            }}
            dangerouslySetInnerHTML={{ __html: text || '' }}
          />
          <div
            className={classnames(styles.learnMore, {
              [styles.isLarge]: isLargeThumbnail,
            })}
            style={{
              textAlign: ctaAlignment ? ctaAlignment : 'left',
            }}
          >
            {firstCtaContent ? (
              <Button
                href={firstCtaContent.url}
                target={firstCtaContent.target || '_self'}
                className={styles.learnMoreBtn}
                buttonSize="small"
              >
                {firstCtaContent.textDisplay}
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  };

  const { contentPadding, viewAllUrl, urlTarget, blockBgColor, ctaName } =
    podcastFeatures?.[0] || {};

  return (
    <div
      id="podcast-block"
      className={styles.podcast_container}
      style={{
        backgroundColor: blockBgColor ? `#${blockBgColor}` : '#FFFFFF',
        ...(contentPadding ? { padding: contentPadding } : {}),
      }}
    >
      <div className={styles.podcast_contents}>
        {podcastIntro && podcastIntro.length > 0 && (
          <div className={styles.podcastIntro}>
            {podcastIntro.map((intro, index) => (
              <PodcastIntro key={index} intro={intro} />
            ))}
          </div>
        )}
        <div className={styles.podcasts}>
          {contents.map((content, index) => (
            <PodcastList key={index} content={content} />
          ))}
        </div>
        {viewAllUrl && (
          <div className={styles.footer}>
            <Button
              href={viewAllUrl}
              target={urlTarget || '_self'}
              buttonSize="small"
              className={styles.viewAllPodcast}
            >
              {ctaName}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PodcastBlock;
