/* eslint-disable no-console */
import React from 'react';
import { PodcastBlock, PodcastSeries } from './components';
import {
  PodcastIntroInterface,
  PodcastListInterface,
  Podcastfeature,
} from './store';

interface Props {
  type: string;
  podcastIntro?: PodcastIntroInterface[];
  podcastBlocks?: PodcastListInterface[];
  podcastFeatures?: Podcastfeature[];
}

const Podcasts: React.FC<Props> = ({
  type,
  podcastIntro,
  podcastBlocks,
  podcastFeatures,
}) => {
  // Wrap JSX inside a single parent element (e.g., a div)
  return (
    <div>
      {type === 'podcastBlock' ? (
        <PodcastBlock
          podcastIntro={podcastIntro}
          podcastBlock={podcastBlocks}
          podcastFeatures={podcastFeatures}
        />
      ) : (
        <PodcastSeries
          podcastBlock={podcastBlocks}
          podcastFeatures={podcastFeatures}
        />
      )}
    </div>
  );
};

export default Podcasts;
